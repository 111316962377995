
const mutations = {
 
}

const actions = {
    
}

const getters = {

}

const state = () => ({
    
})

export default {
    mutations,
    getters,
    actions,
    state,
}
